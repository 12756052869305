<template>
  <div class="icon-bg">
    <div v-for="icon in icons" :key="icon" class="icon-item">
      <svg-icon :icon-class="icon"></svg-icon>
      <span>{{ icon }}</span>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../../svgIcon/index.vue";
export default {
  name: "IconPage",
  components: {SvgIcon},
  data() {
    return {
      icons: [

      ]
    }
  },
  mounted() {
    this.icons.push('new-logo')
    this.icons.push('new-search')
    for (let i=0; i<60; ++i) {
      this.icons.push('new-'+(i<10? '0'+i:i))
    }
  }
}
</script>

<style scoped lang="css">
.icon-bg {
  width: 100%;
  height: 100%;
  background: #0d141e;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
}
span {
  color: #fff;
}
.icon-item {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60px;
  height: 60px;
}
.icon-item svg {
  width: 40px;
  height: 40px;
}
</style>
